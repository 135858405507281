<template>
  <modal
    @close="$emit('close')"
    @submit="submit()"
    :label="`${formAction} Employer`"
    blocking
    medium
    show_cancel
  >
    <template v-slot:content>
      <div class="p-3">
        <div class="flex flex-wrap rounded-t border-b border-dark-purple mb-5">
          <a
            v-for="(tab, key) in tabs"
            :key="key"
            href="javascript:void(0)"
            @click="setOpenTab(key)"
            :class="{
              'text-dark-purple font-semibold rounded-t py-2 px-3': true,
              'bg-purple text-white': openTabKey === key,
              'hover:bg-purple hover:text-white border-t border-x border-purple':
                openTabKey !== key,
            }"
          >
            {{ tab.title }}
          </a>
        </div>
        <!-- Add Employer-->
        <div v-if="openTab === 'add'">
          <div v-if="errorMessage.length" class="mb-5">
            <error-banner
              title="Form Error"
              :messages="errorMessage"
              error
              ref="inlineMessage"
            />
          </div>

          <employer-edit-form v-model="employer" />
        </div>
        <!-- Edit Employer-->
        <div v-if="openTab === 'edit'">
          <div v-if="errorMessage.length" class="mb-5">
            <error-banner
              title="Form Error"
              :messages="errorMessage"
              error
              ref="inlineMessage"
            />
          </div>

          <error-banner
            v-if="!errorMessage.length"
            title="NOTE"
            :messages="[
              'Editing this employer here will edit this employer for all mentors employed at this employer.',
            ]"
            info
          >
          </error-banner>
          <employer-edit-form v-model="employer" />
        </div>
        <!-- Select Employer-->
        <div v-if="openTab === 'select'">
          <FindEmployer @setEmployer="setExistingEmployer" />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/modal/Modal";
import EmployerEditForm from "@/components/reusable-components/EmployerEditForm";
import FindEmployer from "@/views/admin/mentors/mentor-edits/FindEmployer";
import ErrorBanner from "@/components/forms/ErrorBanner";
import { post, put } from "@/composables/httpUtil";

export default {
  name: "AddEditEmployer",
  components: {
    ErrorBanner,
    FindEmployer,
    Modal,
    EmployerEditForm,
  },
  emits: ["close", "submit"],
  props: {
    currentEmployer: { type: Object, required: true },
  },
  data() {
    return {
      employer: {
        employer_id: null,
        employer_name: null,
        web_site: null,
        number_employees: null,
        display_employer: true,
        address: {
          address_id: null,
          address_type: "WORK",
          address_line_one: null,
          address_line_two: null,
          city: null,
          state: null,
          zip_code: null,
          is_primary: true,
          is_public: true,
        },
        phone: {
          phone_id: null,
          phone_type: "WORK",
          phone_number: null,
          is_public: true,
          is_primary: true,
        },
      },
      openTab: "",
      openTabKey: 0,
      is_new: false,
      errorMessage: [],
    };
  },
  created() {
    this.employer = {
      ...this.currentEmployer,
      address: { ...this.currentEmployer.address },
      phone: { ...this.currentEmployer.phone },
    };
    this.setOpenTab(this.formAction === "Edit" ? 1 : 0);
  },
  methods: {
    put,
    post,
    setOpenTab: function (key) {
      this.openTabKey = key;
      if (this.tabs.length === 3) {
        switch (key) {
          case 0:
            this.clearEmployer();
            this.openTab = "add";
            break;
          case 1:
            this.setEmployerFromCurrent();
            this.openTab = "edit";
            break;
          case 2:
            this.openTab = "select";
            break;
        }
      } else {
        switch (key) {
          case 0:
            this.openTab = "add";
            break;
          case 1:
            this.openTab = "select";
            break;
        }
      }
    },
    setEmployerFromCurrent: function () {
      this.employer = {
        ...this.currentEmployer,
        address: { ...this.currentEmployer.address },
        phone: { ...this.currentEmployer.phone },
      };
    },
    clearEmployer: function () {
      this.employer.employer_id = null;
      this.employer.employer_name = null;
      this.employer.web_site = null;
      this.employer.number_employees = null;
      this.employer.display_employer = true;
      this.employer.address.address_id = null;
      this.employer.address.address_type = "WORK";
      this.employer.address.address_line_one = null;
      this.employer.address.address_line_two = null;
      this.employer.address.city = null;
      this.employer.address.state = null;
      this.employer.address.zip_code = null;
      this.employer.address.is_primary = true;
      this.employer.address.is_public = true;
      this.employer.phone.phone_id = null;
      this.employer.phone.phone_type = "WORK";
      this.employer.phone.phone_number = null;
      this.employer.phone.is_public = true;
      this.employer.phone.is_primary = true;
    },
    setExistingEmployer: function (e) {
      this.setOpenTab(1);
      this.employer.employer_id = e.employer_id;
      this.employer.employer_name = e.employer_name;
      this.employer.web_site = e.web_site;
      this.employer.number_employees = e.number_employees;
      this.employer.display_employer = e.display_employer;
      if (e.address) {
        this.employer.address.address_id = e.address.address_id;
        this.employer.address.address_type = e.address.address_type;
        this.employer.address.address_line_one = e.address.address_line_one;
        this.employer.address.address_line_two = e.address.address_line_two;
        this.employer.address.city = e.address.city;
        this.employer.address.state = e.address.state;
        this.employer.address.zip_code = e.address.zip_code;
        this.employer.address.is_primary = e.address.is_primary;
        this.employer.address.is_public = e.address.is_public;
      } else {
        this.employer.address.address_id = null;
        this.employer.address.address_type = "WORK";
        this.employer.address.address_line_one = null;
        this.employer.address.address_line_two = null;
        this.employer.address.city = null;
        this.employer.address.state = null;
        this.employer.address.zip_code = null;
        this.employer.address.is_primary = true;
        this.employer.address.is_public = true;
      }
      if (e.phone) {
        this.employer.phone.phone_id = e.phone.phone_id;
        this.employer.phone.phone_type = e.phone.phone_type;
        this.employer.phone.phone_number = e.phone.phone_number;
        this.employer.phone.is_public = e.phone.is_public;
        this.employer.phone.is_primary = e.phone.is_primary;
      } else {
        this.employer.phone.phone_id = null;
        this.employer.phone.phone_type = "WORK";
        this.employer.phone.phone_number = null;
        this.employer.phone.is_public = true;
        this.employer.phone.is_primary = true;
      }
    },
    formatEmp: function () {
      if (this.employer.phone.phone_type == null) {
        this.employer.phone.phone_type = "WORK";
      }
      if (this.employer.address.address_type == null) {
        this.employer.address.address_type = "WORK";
      }
    },
    validateForm: function () {
      this.errorMessage = [];
      if (!this.employer.employer_name) {
        this.errorMessage.push("Need Employer Name");
      }
      // Check all three required fields for address
      if (
        this.employer.address.address_line_one ||
        this.employer.address.city ||
        this.employer.address.zip_code
      ) {
        if (!this.employer.address.address_line_one) {
          this.errorMessage.push("Need Street Address");
        }
        if (!this.employer.address.city) {
          this.errorMessage.push("Need City for Address");
        }
        if (!this.employer.address.zip_code) {
          this.errorMessage.push("Need Zip Code for Address");
        }
      }
      if (this.errorMessage.length) {
        return false;
      }
      return true;
    },
    submit() {
      // Save to the server if new or edited
      if (this.validateForm()) {
        this.formatEmp();
        (this.openTab === "add"
          ? this.post("admin/employers", this.employer)
          : this.put(
              "admin/employers/" + this.employer.employer_id,
              this.employer
            )
        )
          .then((response) => {
            this.$emit("submit", response);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  computed: {
    formAction() {
      return this.currentEmployer.employer_id ? "Edit" : "Add";
    },
    tabs() {
      let tabs = [
        { title: `${this.formAction} Employer` },
        { title: "Select Employer" },
      ];
      if (this.formAction === "Edit") {
        tabs = [{ title: "Add New Employer" }, ...tabs];
      }
      return tabs;
    },
  },
};
</script>

<style scoped></style>
