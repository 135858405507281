<template>
  <div>
    <AddEditEmployer
      :current-employer="current_employer"
      v-if="show_employer_modal"
      @submit="updateEmployerData"
      @close="show_employer_modal = !show_employer_modal"
    />
    <div v-if="addView" class="header-text pb-3" data-cy="header-add-Employers">
      Add Mentor
    </div>
    <div v-else class="header-text pb-3" data-cy="header-edit-Employer">
      Edit {{ fullName }}
    </div>
    <div v-if="loading">
      <div class="box-style">
        <LoadingSpinner />
      </div>
    </div>
    <div v-else>
      <div v-if="errorMessage.length" class="mb-5">
        <error-banner
          title="Form Error"
          :messages="errorMessage"
          error
          ref="inlineMessage"
        />
      </div>
      <div class="box-style mb-5">
        <div class="thirdhead-text">Mentor Details</div>
        <div class="profile_box grid grid-cols-2 gap-5">
          <div>
            <div class="mb-3">
              <div class="mb-2">
                <label for="dropdown-prefix"> Prefix </label>
              </div>
              <select
                v-model="mentor_prefix"
                id="dropdown-prefix"
                class="py-2 px-3 w-full rounded border border-steel-gray"
              >
                <option :value="null"></option>
                <option
                  v-for="(prefix, index) in lookup_prefix_options"
                  :value="prefix"
                  :key="index"
                  class="h-8"
                >
                  {{ prefix }}
                </option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="mb-3" v-if="show_other_prefix">
              <div class="mb-2">
                <label for="textbox-otherSuffix">Other Prefix</label>
              </div>
              <input
                id="textbox-otherPrefix"
                type="text"
                class="py-2 px-3 w-full rounded border border-steel-gray"
                v-model.trim="mentor_prefix"
                maxlength="100"
              />
            </div>

            <div class="mb-3">
              <div class="mb-2">
                <label class="" for="textbox-nickname"> Preferred Name </label>
              </div>
              <input
                id="textbox-nickname"
                type="text"
                class="py-2 px-3 w-full rounded border border-steel-gray"
                v-model.trim="nickname"
                maxlength="500"
              />
            </div>

            <div class="mb-3">
              <div class="mb-2">
                <label class="" for="textbox-firstname"> First Name </label>
              </div>
              <input
                id="textbox-firstname"
                type="text"
                class="py-2 px-3 w-full rounded border border-steel-gray"
                v-model.trim="firstName"
                maxlength="500"
              />
            </div>

            <div class="mb-3">
              <div class="mb-2">
                <label for="textbox-middleName"> Middle Name </label>
              </div>
              <input
                id="textbox-middleName"
                type="text"
                class="py-2 px-3 w-full rounded border border-steel-gray"
                v-model.trim="middleName"
                maxlength="500"
              />
            </div>

            <div class="mb-3">
              <div class="mb-2">
                <label for="textbox-lastName"> Last Name </label>
              </div>
              <input
                id="textbox-lastName"
                type="text"
                class="py-2 px-3 w-full rounded border border-steel-gray"
                v-model.trim="lastName"
                maxlength="500"
              />
            </div>

            <div class="mb-3">
              <div class="mb-2">
                <label for="dropdown-suffix"> Suffix </label>
              </div>
              <select
                id="dropdown-suffix"
                class="py-2 px-3 w-full rounded border border-steel-gray"
                v-model="mentor_suffix"
              >
                <option :value="null"></option>
                <option
                  v-for="(suffix, key) in lookup_suffix_options"
                  :key="`suffix_${key}`"
                  :value="suffix"
                >
                  {{ suffix }}
                </option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="mb-3" v-if="show_other_suffix">
              <div class="mb-2">
                <label for="textbox-otherSuffix">Other Suffix</label>
              </div>
              <input
                id="textbox-otherSuffix"
                type="text"
                class="py-2 px-3 w-full rounded border border-steel-gray"
                v-model.trim="mentor_suffix"
                maxlength="100"
              />
            </div>
            <div class="hidden">Mentor Since: {{ formatDate }}</div>
          </div>
          <div>
            <!-- ethnicity -->
            <div class="mb-2">Ethnicity</div>
            <!--            <div class="pb-2">How would you classify yourself?</div>-->
            <div class="pb-3">
              <div
                v-for="(value, idx) in lookup_ethnicity"
                :key="`ethnicity_${idx}`"
              >
                <label>
                  <input
                    :id="`ethnicity_${idx}`"
                    :data-cy="`ethnicity_${idx}`"
                    name="ethnicity-check"
                    :aria-label="
                      'checkbox for race that applies ' + value.races
                    "
                    type="checkbox"
                    class="my-1 mr-1 rounded"
                    :value="value"
                    v-model="mentor_ethnicity"
                  />
                  {{ value.race }}
                </label>
              </div>
              <label for="textbox-addotherethnicity">
                <input
                  id="textbox-addotherethnicity"
                  name="ethnicity-check"
                  type="checkbox"
                  class="my-1 mr-1 rounded"
                  v-model="included_other_ethnicity"
                  data-cy="edit-student-other-ethnicity"
                />
                Add another ethnicity
              </label>
              <div v-if="included_other_ethnicity" class="pt-2">
                <input
                  type="text"
                  aria-label="other ethnicity"
                  class="border-steel-gray rounded h-8 w-1/2"
                  v-model="ethnicity_custom.race"
                  placeholder="Add another ethnicity"
                  data-cy="edit-student-other-ethnicity-textfield"
                />
              </div>
            </div>

            <!-- gender -->
            <div class="mb-2">Gender</div>
            <div class="pb-3">
              <ul>
                <li
                  v-for="(gender_option, idx) in lookup_gender_options"
                  :key="`gender_${idx}`"
                >
                  <label :for="`gender_${idx}`">
                    <input
                      :id="`gender_${idx}`"
                      :data-cy="`gender_${idx}`"
                      name="gender_option"
                      type="radio"
                      class="my-1 mr-1"
                      :value="gender_option"
                      v-model="mentor_gender"
                    />
                    {{ gender_option }}
                  </label>
                </li>
              </ul>
              <div
                v-if="
                  mentor_gender !== null &&
                  (mentor_gender === 'Add another gender' ||
                    !lookup_gender_options.includes(mentor_gender))
                "
                class="pt-2"
              >
                <input
                  type="text"
                  aria-label="add another gender"
                  class="border-steel-gray rounded h-8 w-1/2"
                  v-model="mentor_gender"
                  placeholder="Add another gender"
                  data-cy="edit-student-other-gener-textfield"
                />
              </div>
            </div>

            <!-- pronouns -->

            <div class="mb-2">Pronouns</div>
            <!--          <div class="pb-2">Check one or more options.</div>-->
            <div class="pb-3">
              <ul>
                <li
                  v-for="(pronouns_option, idx) in lookup_pronouns_options"
                  :key="`pronouns_${idx}`"
                >
                  <label :for="`pronouns_${idx}`">
                    <input
                      :id="`pronouns_${idx}`"
                      :data-cy="`pronouns_${idx}`"
                      name="pronoun_option"
                      type="radio"
                      class="my-1 mr-1"
                      :value="pronouns_option"
                      v-model="mentor_pronouns"
                    />
                    {{ pronouns_option }}
                  </label>
                </li>
              </ul>
              <div
                v-if="
                  mentor_pronouns !== null &&
                  (mentor_pronouns === 'Add another pronoun set' ||
                    !lookup_pronouns_options.includes(mentor_pronouns))
                "
                class="pt-2"
              >
                <input
                  type="text"
                  aria-label="add another pronoun"
                  class="border-steel-gray rounded h-8 w-1/2"
                  v-model="mentor_pronouns"
                  placeholder="Add another pronoun set"
                  data-cy="edit-student-other-pronouns-textfield"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-style mb-5">
        <div class="thirdhead-text">Mentor Contact Info</div>
        <div class="profile_box">
          <div class="grid grid-cols-1 gap-1">
            <div class="col-span-1">
              <div class="mb-3">
                <div class="mb-2">Email</div>
                <div
                  v-for="(email, index) in this.emails"
                  :key="email?.email_id"
                >
                  <div
                    class="grid grid-cols-8 gap-1 items-center mb-1"
                    v-if="
                      emailControl[index] === undefined ||
                      emailControl[index] === false
                    "
                  >
                    <font-awesome-icon
                      class="text-dark-purple"
                      icon="envelope"
                      aria-label="envelope icon to identify email section"
                    />
                    <div>{{ email?.email_type }}</div>
                    <div class="col-span-2">{{ email?.email_address }}</div>
                    <div>
                      {{ email?.is_primary === true ? "Primary" : "" }}
                    </div>
                    <div>
                      {{ email?.is_public === true ? "Visible" : "" }}
                    </div>
                    <font-awesome-icon
                      @click="emailControl[index] = true"
                      class="text-purple col-start-7 hover:cursor-pointer"
                      icon="edit"
                      aria-label="edit email"
                    />
                    <font-awesome-icon
                      @click="this.emails.splice(index, 1)"
                      class="text-error-text hover:cursor-pointer"
                      icon="trash"
                      aria-label="delete"
                    />
                  </div>
                  <AddEditEmail
                    v-else
                    :editEmails="email"
                    @cancelChange="emailControl[index] = false"
                    @emailChanged="emailUpdated($event, index)"
                  />
                </div>
                <!-- <Button
              v-if="this.addEmail === false"
              text="Add Email"
              :primary="false"
              @click="this.addEmail = true"
            /> -->
                <a
                  v-if="!this.addEmail"
                  aria-label="Add Email"
                  @click="this.addEmail = true"
                  class="hover:cursor-pointer text-purple"
                  href="javascript:void(0)"
                >
                  <font-awesome-icon icon="plus" />
                </a>
                <AddEditEmail
                  v-else
                  @cancelChange="this.addEmail = false"
                  @emailChanged="addEmailAddress($event)"
                />
              </div>
              <div class="mb-3">
                <div class="mb-2">Phone</div>
                <div
                  v-for="(phone, index) in this.phoneNumbers"
                  :key="phone?.phone_id"
                >
                  <div
                    class="grid grid-cols-8 gap-1 items-center"
                    v-if="
                      phoneControl[index] === undefined ||
                      phoneControl[index] === false
                    "
                  >
                    <font-awesome-icon
                      class="text-dark-purple"
                      icon="phone"
                      aria-label="phone icon to identify phone section"
                    />
                    <div>
                      {{ phone?.phone_type }}
                    </div>
                    <div class="col-span-2">{{ phone?.phone_number }}</div>
                    <div>{{ phone?.is_primary === true ? "Primary" : "" }}</div>
                    <div>{{ phone?.is_public === true ? "Visible" : "" }}</div>

                    <font-awesome-icon
                      class="text-purple col-start-7 hover:cursor-pointer"
                      icon="edit"
                      aria-label="edit phone number"
                      @click="phoneControl[index] = true"
                    />
                    <font-awesome-icon
                      class="text-error-text hover:cursor-pointer"
                      icon="trash"
                      aria-label="delete phone number"
                      @click="this.phoneNumbers.splice(index, 1)"
                    />
                  </div>
                  <div v-else>
                    <AddEditPhone
                      :editPhone="phone"
                      @cancelChange="phoneControl[index] = false"
                      @phoneNumberChanged="phoneNumberEdited($event, index)"
                    />
                  </div>
                </div>
                <div v-if="this.addPhone">
                  <AddEditPhone
                    @cancelChange="this.addPhone = false"
                    @phoneNumberChanged="addPhoneNumber($event)"
                  />
                </div>
                <div v-if="!this.addPhone">
                  <a
                    href="javascript:void(0)"
                    class="hover:cursor-pointer text-purple"
                    aria-label="Add Phone"
                    @click="
                      this.addPhone
                        ? (this.addPhone = false)
                        : (this.addPhone = true)
                    "
                  >
                    <font-awesome-icon icon="plus" />
                  </a>
                </div>
              </div>
              <div class="mb-2">Address</div>
              <div
                v-for="(address, index) in this.addresses"
                :key="address?.address_id"
                class="grid grid-cols-8 gap-1"
              >
                <template
                  v-if="
                    addressControl[index] === undefined ||
                    addressControl[index] === false
                  "
                >
                  <font-awesome-icon
                    class="text-dark-purple"
                    icon="building"
                    aria-label="building icon to identify address section"
                  />
                  <div class="col-span-1">{{ address.address_type }}</div>
                  <div class="col-span-2">
                    {{ address.address_line_one }}
                    {{ address.address_line_two }}
                    {{ address.city }}
                    {{ address.state }},
                    {{ address.zip_code }}
                  </div>
                  <div class="col-span-1">
                    {{ address.is_primary === true ? "Primary" : " " }}
                  </div>
                  <div class="col-span-1">
                    {{ address.is_public === true ? "Visible" : " " }}
                  </div>
                  <font-awesome-icon
                    class="text-purple col-start-7 hover:cursor-pointer"
                    icon="edit"
                    aria-label="edit phone number"
                    @click="addressControl[index] = true"
                  />
                  <font-awesome-icon
                    class="text-error-text hover:cursor-pointer"
                    icon="trash"
                    aria-label="delete address"
                    @click="this.addresses.splice(index, 1)"
                  />
                </template>
                <AddEditAddress
                  class="col-span-full"
                  v-else
                  :editAddress="address"
                  @cancelChange="addressControl[index] = false"
                  @addressChanged="addressUpdated($event, index)"
                />
              </div>
              <a
                href="javascript:void(0)"
                v-if="this.addAddress === false"
                class="hover:cursor-pointer text-purple"
                aria-label="Add Address"
                @click="this.addAddress = true"
              >
                <font-awesome-icon icon="plus" />
              </a>
              <AddEditAddress
                v-else
                @cancelChange="this.addAddress = false"
                @addressChanged="addPhysicalAddress($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="box-style mb-5">
        <div class="thirdhead-text">Employer</div>
        <div class="profile_box">
          <div class="flex justify-between mb-5">
            <div>
              <div class="font-semibold text-dark-purple mb-1">
                {{ current_employer.employer_name }}
              </div>
              <div class="mb-2">
                <font-awesome-icon
                  class="text-dark-purple mr-1"
                  icon="globe"
                  aria-label="globe icon to identify website section"
                />
                <a
                  v-if="current_employer.web_site"
                  :href="current_employer.web_site"
                  class="underline text-purple mb-0"
                  target="_blank"
                  >{{ current_employer.web_site }}
                </a>
                <span v-else>No Employer Website</span>
              </div>

              <div class="mb-2">
                <font-awesome-icon
                  class="text-dark-purple mr-1"
                  icon="phone"
                  aria-label="phone icon to identify phone section"
                />
                {{
                  current_employer?.phone?.phone_number
                    ? current_employer.phone.phone_number
                    : "No Employer Phone Number"
                }}
              </div>

              <div class="flex justify-start">
                <font-awesome-icon
                  class="text-dark-purple mr-1"
                  icon="building"
                  aria-label="building icon to identify address section"
                />
                <div v-if="current_employer?.address">
                  <div>
                    {{ current_employer.address?.address_line_one }}
                  </div>
                  <div>
                    {{ current_employer.address?.address_line_two }}
                  </div>
                  <div>
                    {{ current_employer.address?.city }}
                    {{ current_employer.address?.state }}
                    {{ current_employer.address?.zip_code }}
                  </div>
                </div>
                <div v-else>
                  <p>No Employer Address</p>
                </div>
              </div>
            </div>
            <div class="text-right">
              <Button
                :primary="false"
                :text="`${
                  current_employer.employer_id ? 'Edit' : 'Add'
                } Employer`"
                @click="show_employer_modal = !show_employer_modal"
              />
            </div>
          </div>
          <div class="mb-2">
            <label class="cols-auto" for="textbox-position"> Position </label>
          </div>
          <input
            id="textbox-position"
            type="text"
            class="w-1/2 rounded border border-steel-gray"
            v-model.trim="current_mentor_employer_job_title"
            maxlength="500"
          />
        </div>
      </div>
      <div class="box-style mb-5">
        <div class="thirdhead-text">Mentor Profile</div>
        <div class="profile_box">
          <label class="mb-1">Practice Areas</label>
          <div class="w-2/3">
            <draggable-list
              v-model="profile_practiceAreas"
              @remove="removePracticeArea"
              list_of="practice_areas"
              identifier="practice_area_id"
              data-cy="interests-list"
            />
            <div class="flex justify-start my-2">
              <div>
                <Button
                  :primary="false"
                  text="Add Practice Area"
                  @click="show_practice_area_modal = !show_practice_area_modal"
                />
              </div>
              <ListLookupModal
                v-if="show_practice_area_modal"
                class="w-1/2 left-1/4"
                :items="filtered_lookup_practice_areas"
                @addSelection="pushPracticeAreaSelection"
                @closeModal="
                  show_practice_area_modal = !show_practice_area_modal
                "
                data-cy="list-lookup-modal-interests"
              />
            </div>
          </div>
        </div>
        <div class="profile_box">
          <div class="mb-2">
            <label for="dropdown-status">Mentor Status</label>
          </div>
          <div class="mb-3">
            <select
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model="profile_mentorStatus"
              id="dropdown-status"
            >
              <option
                v-for="(status, key) in lookup_mentor_status_options"
                :key="key"
                :value="status"
              >
                {{ status }}
              </option>
            </select>
          </div>
          <div class="mb-2">
            <label for="dropdown-preferred-academic-level"
              >Preferred Academic Level</label
            >
          </div>
          <div class="mb-3">
            <select
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model="profile_preferedAcademicLevel"
              id="dropdown-preferred-academic-level"
            >
              <option :value="null"></option>
              <option
                v-for="academicLevel in lookup_acad_levels"
                :key="academicLevel.acad_level_id"
                :value="academicLevel.acad_level_id"
              >
                {{ academicLevel.level }}
              </option>
            </select>
          </div>
          <div class="mb-2">
            <label class="col-span-2 col-start-1" for="dropdown-mentor-alumni"
              >Alumni ?</label
            >
          </div>
          <div class="mb-3">
            <select
              class="py-2 px-3 w-full rounded border border-steel-gray"
              id="dropdown-mentor-alumni"
              v-model="profile_isAlumni"
            >
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>
          <div class="mb-2">
            <label v-if="profile_isAlumni" for="textbox-mentor-alumnus-year"
              >Alumnus Year</label
            >
          </div>
          <div class="mb-3">
            <input
              v-if="profile_isAlumni"
              type="number"
              class="py-2 px-3 w-full rounded border border-steel-gray"
              id="textbox-mentor-alumnus-year"
              v-model.trim="profile_alumnusYear"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="text-right pt-3">
      <router-link to="/admin/mentors" class="px-5">
        <Button text="Cancel" @click="$router.back()" />
      </router-link>

      <button
        class="px-4 py-2 inline-block rounded-full hover:bg-dark-purple bg-purple text-white disabled:bg-light-gray disabled:text-slate-gray"
        @click="submitForm"
      >
        <span v-if="errorMessage.length !== 0">See Errors</span>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";
import AddEditPhone from "./mentor-edits/AddEditPhone";
import AddEditEmail from "./mentor-edits/AddEditEmail";
import AddEditAddress from "./mentor-edits/AddEditAddress";
import AddEditEmployer from "./mentor-edits/AddEditEmployer";
import { DateTime, FormatDate } from "@/composables/date_utils";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { post, get, put } from "@/composables/httpUtil";
import { mapActions, mapGetters } from "vuex";
import DraggableList from "@/components/draggable/DraggableList";
import ListLookupModal from "@/components/lookup/ListLookupModal";
import ErrorBanner from "@/components/forms/ErrorBanner";

export default {
  name: "MentorProfileEdit",
  components: {
    ErrorBanner,
    AddEditEmployer,
    Button,
    LoadingSpinner,
    AddEditPhone,
    AddEditEmail,
    AddEditAddress,
    DraggableList,
    ListLookupModal,
  },
  mounted() {
    this.loading = true;
    Promise.all([this.setup_mentor(), this.fetchLookupResource()])
      .catch((error) => {
        console.error(error);
      })
      .finally(() => (this.loading = false));
  },
  data() {
    return {
      id: null,
      mentor_prefix: null,
      nickname: null,
      firstName: null,
      lastName: null,
      middleName: null,
      mentor_suffix: null,
      emails: [],
      phoneNumbers: [],
      addresses: [],
      mentor_gender: null,
      mentor_pronouns: null,
      mentorSince: null,

      mentor_ethnicity: [],
      included_other_ethnicity: false,
      ethnicity_custom: { race: null, description: "", display: false },

      //employment information
      current_mentor_employer_id: null,
      current_mentor_employer_start_date: null,
      current_mentor_employer_end_date: null,
      current_mentor_employer_job_title: null,
      current_employer: {
        employer_name: null,
        web_site: null,
        number_employees: null,
        display_employer: true,
        job_title: null,
        address: {
          address_id: null,
          address_type: "WORK",
          address_line_one: null,
          address_line_two: null,
          city: null,
          state: null,
          zip_code: null,
          is_primary: true,
          is_public: true,
        },
        phone: {
          phone_type: "WORK",
          phone_number: null,
          is_public: true,
          is_primary: true,
        },
      },
      employer_list: [],

      //Mentor Profile
      profile_id: null,
      profile_practiceAreas: [],
      profile_mentorStatus: null,
      profile_alumnusYear: null,
      profile_christmasCards: false,
      profile_preferedAcademicLevel: null,
      profile_isAlumni: false,

      loading: false,
      addView: true,
      editMentor: undefined,

      addPhone: false,
      addEmail: false,
      addAddress: false,

      //edit controls
      phoneControl: {},
      emailControl: {},
      addressControl: {},

      errorMessage: [],
      saveText: "Save",

      show_other_prefix: false,
      show_other_suffix: false,
      show_employer_modal: false,
      show_practice_area_modal: false,
    };
  },
  watch: {
    mentor_suffix(new_suffix) {
      this.show_other_suffix =
        (new_suffix === "Other" ||
          !this.lookup_suffix_options.includes(new_suffix)) &&
        new_suffix;
    },
    mentor_prefix(new_prefix) {
      this.show_other_prefix =
        (new_prefix === "Other" ||
          !this.lookup_prefix_options.includes(new_prefix)) &&
        new_prefix;
    },
  },
  methods: {
    FormatDate,
    get,
    put,
    post,
    ...mapActions(["fetchLookupResource"]),
    setup_mentor() {
      return new Promise((resolve, reject) => {
        if (this.$route.fullPath.includes("/add")) {
          this.addView = true;
        } else {
          this.addView = false;

          this.id = this.$route.params.id;
          this.get("admin/mentors/" + this.id)
            .then((results) => {
              this.editMentor = results;

              this.mentor_prefix = results.prefix;
              this.nickname = results.nickname;
              this.firstName = results.firstname;
              this.lastName = results.lastname;
              this.middleName = results.middlename;
              this.mentor_suffix = results.suffix;

              this.mentor_gender = results.gender;

              this.mentor_pronouns = results.pronouns;

              results.ethnicity.forEach((eth) => {
                if (
                  this.lookup_ethnicity.findIndex(
                    (e) => e.ethnicity_id === eth.ethnicity_id
                  ) >= 0
                ) {
                  this.mentor_ethnicity.push(eth);
                } else {
                  this.included_other_ethnicity = true;
                  this.ethnicity_custom = eth;
                }
              });

              this.emails = results.email;

              this.phoneNumbers = results.phone;

              this.addresses = results.address;

              this.mentorSince = results.added;

              // current employer
              if (results.employer.length > 0) {
                this.employer_list = results.employer;
                const current_employer = results.employer.find(
                  (emp) => emp.current
                );

                this.current_mentor_employer_id =
                  current_employer?.mentor_employer_id;
                this.current_mentor_employer_start_date =
                  current_employer?.from_date;
                this.current_mentor_employer_end_date =
                  current_employer?.to_date;
                this.current_mentor_employer_job_title =
                  current_employer?.job_title;

                if (current_employer) {
                  this.current_employer = current_employer?.employer;
                }
              }
              //mentor profile
              const mentorProfile = results.profile;
              this.profile_id = mentorProfile.mentor_profile_id;
              this.profile_practiceAreas = [
                ...mentorProfile.practice_areas,
              ].sort((a, b) => a.priority - b.priority);
              this.profile_mentorStatus = results.mentor_status
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() +
                    word.substring(1).toLowerCase()
                )
                .join(" ");
              this.profile_alumnusYear = mentorProfile.alumnus_year;
              if (this.profile_alumnusYear > 0) {
                //if alumni year is set then they are an alumni so setting the boolean value as such
                this.profile_isAlumni = true;
              }
              this.profile_christmasCards = mentorProfile.religious_greeting;
              this.profile_preferedAcademicLevel =
                mentorProfile.preferred_acad_level;

              //pairings
              this.student_pairings = results.student_pairings;
            })
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(Error(err));
            });
        }
        resolve();
      });
    },
    submitForm() {
      if (this.validateForm()) {
        this.loading = true;
        const mentor = this.formatMentor();

        (this.addView
          ? this.post("admin/mentors", mentor)
          : this.put("admin/mentors/" + this.id, mentor)
        )
          .then((response) => {
            this.$router.push(`/admin/mentors/view/${response.mentor_id}`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    validateForm() {
      this.errorMessage = [];
      if (!this.firstName) {
        this.errorMessage.push("Please add a first name");
      }
      if (!this.lastName) {
        this.errorMessage.push("Please add a last name");
      }
      if (!this.profile_mentorStatus) {
        this.errorMessage.push("Please select a status for the mentor");
      }
      return !this.errorMessage.length;
    },
    formatMentor() {
      let mentor = {
        mentor_id: this.id,
        prefix: this.mentor_prefix,
        firstname: this.firstName,
        lastname: this.lastName,
        middlename: this.middleName,
        nickname: this.nickname,
        suffix: this.mentor_suffix,
        student_admit_term: null,
        student_completion_term: null,
        mentor_msaccess_id: null,
        mentor_status: this.profile_mentorStatus
          .replace(/\s/g, "_")
          .toUpperCase(),
        mentor_is_ust_contact: null,
        student_pairings: this.student_pairings,
        gender: this.mentor_gender,
        pronouns: this.mentor_pronouns,
        ethnicity: this.mentor_ethnicity,
        address: this.formatAddresses(),
        phone: this.formatPhones(),
        email: this.formatEmails(),
        profile: this.formatProfile(),
        employer: this.employer_list,
      };

      delete mentor.student_pairings;

      // Add Job Title
      mentor.employer.forEach((e) => {
        if (e.current === true) {
          e.job_title = this.current_mentor_employer_job_title;
        }
      });

      if (this.included_other_ethnicity) {
        mentor.ethnicity.push(this.ethnicity_custom);
      }
      return mentor;
    },
    formatAddresses() {
      return this.addresses.map((address) => {
        return {
          address_id: address.address_id,
          address_type: address.address_type,
          address_line_one: address.address_line_one,
          address_line_two: address.address_line_two,
          city: address.city,
          state: address.state,
          zip_code: address.zip_code,
          from_date: address.from_date,
          to_date: address.to_date,
          updated: address.updated,
          added: address.added,
          deleted: address.deleted,
          is_primary: address.is_primary,
          is_public: address.is_public,
        };
      });
    },
    formatEmails() {
      return this.emails.map((email) => {
        return {
          email_id: email.email_id,
          email_address: email.email_address,
          email_type_id: email.email_type_id,
          person_id: email.person_id,
          email_type: email.email_type,
          added: email.added,
          updated: email.updated,
          deleted: email.deleted,
          is_public: email.is_public,
          is_primary: email.is_primary,
        };
      });
    },
    formatPhones() {
      return this.phoneNumbers.map((phone) => {
        return {
          phone_id: phone.phone_id,
          phone_type: phone.phone_type,
          phone_number: phone.phone_number,
          added: phone.added,
          updated: phone.updated,
          deleted: phone.deleted,
          is_public: phone.is_public,
          is_primary: phone.is_primary,
        };
      });
    },
    formatProfile() {
      return {
        mentor_profile_id: this.profile_id,
        mentor_id: this.id,
        practice_areas: this.formatPracticeAreas(),
        preferred_acad_level: this.profile_preferedAcademicLevel,
        recruited_by_admin_id: null,
        updated_by_admin_id: null,
        religious_greeting: this.profile_christmasCards,
        alumnus_year: this.profile_alumnusYear,
      };
    },
    formatPracticeAreas() {
      return this.profile_practiceAreas.map((practice, order) => {
        practice.priority = order;
        return practice;
      });
    },
    updateEmployerData(employer) {
      console.log("updateEmployerData", employer);
      this.show_employer_modal = !this.show_employer_modal;
      this.current_employer = employer;

      let present_index = this.employer_list.findIndex(
        (emp) => emp.employer_id === employer.employer_id
      );

      if (present_index === -1) {
        // - New Employer
        this.current_mentor_employer_id == null;
        this.current_mentor_employer_job_title == null;
        this.employer_list.push({
          mentor_employer_id: null,
          mentor_id: this.id,
          employer_id: employer.employer_id,
          job_title: null,
          from_date: null,
          to_date: null,
          employer: employer,
        });
        present_index = this.employer_list.findIndex(
          (emp) => emp.employer_id === employer.employer_id
        );
      }

      // Set all in list to current = false
      this.employer_list.forEach((emp) => {
        emp.current = false;
        if (emp.to_date == null) {
          emp.to_date = DateTime();
        }
      });

      // Set incoming Employer current = true
      this.employer_list[present_index].current = true;
      this.employer_list[present_index].to_date = null;
      if (this.employer_list[present_index].from_date == null) {
        this.employer_list[present_index].from_date = DateTime();
      }
    },
    addPhoneNumber(phone) {
      if (phone.is_primary) {
        this.phoneNumbers.forEach((phone) => {
          phone.is_primary = false;
        });
      }

      this.addPhone = false;
      this.phoneNumbers.push(phone);
    },
    phoneNumberEdited(phone, index) {
      if (phone.is_primary) {
        this.phoneNumbers.forEach((phone) => {
          phone.is_primary = false;
        });
      }

      this.phoneControl[index] = false;
      this.phoneNumbers[index] = phone;
    },
    addEmailAddress(email) {
      if (email.is_primary) {
        this.emails.forEach((email) => {
          email.is_primary = false;
        });
      }

      this.addEmail = false;
      this.emails.push(email);
    },
    emailUpdated(email, index) {
      if (email.is_primary) {
        this.emails.forEach((email) => {
          email.is_primary = false;
        });
      }

      this.emailControl[index] = false;
      this.emails[index] = email;
    },
    addPhysicalAddress(address) {
      if (address.is_primary) {
        this.addresses.forEach((address) => {
          address.is_primary = false;
        });
      }

      this.addAddress = false;
      this.addresses.push(address);
    },
    addressUpdated(address, index) {
      if (address.is_primary) {
        this.addresses.forEach((address) => {
          address.is_primary = false;
        });
      }

      this.addressControl[index] = false;
      this.addresses[index] = address;
    },
    pushPracticeAreaSelection: function (selection) {
      this.profile_practiceAreas = [
        ...this.profile_practiceAreas,
        ...selection,
      ];
      this.show_practice_area_modal = !this.show_practice_area_modal;
    },
    removePracticeArea: function (index) {
      this.profile_practiceAreas.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters([
      "lookup_practice_areas",
      "lookup_acad_levels",
      "lookup_suffix_options",
      "lookup_mentor_status_options",
      "lookup_ethnicity",
      "lookup_pronouns_options",
      "lookup_gender_options",
      "lookup_prefix_options",
    ]),
    fullName() {
      let name = `${this.firstName} ${this.middleName} ${this.lastName} ${
        this.nickname ? "(" + this.nickname + ")" : ""
      }`;

      if (name) {
        name = `${this.mentor_prefix ? this.mentor_prefix : ""} ${name} ${
          this.mentor_suffix ? this.mentor_suffix : ""
        }`;
      }
      return name;
    },
    formatDate() {
      return FormatDate(this.mentorSince);
    },
    filtered_lookup_practice_areas() {
      return this.lookup_practice_areas
        .filter(
          (pa) =>
            !this.profile_practiceAreas
              .map((i) => i.practice_area_id)
              .includes(pa.practice_area_id)
        )
        .sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
    },
  },
};
</script>

<style scoped></style>
